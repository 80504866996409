<template>
    <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
      <el-form ref="form" :model="options" :rules="rules" label-width="106px">
        <topOperatingButton
          :isExamineBtn="true"
          :showAudit="false"
          :isStopBtn="false"
          id="topOperatingButton"
          ref="topOperatingButton"
          @submitForm="submitForm(false)"
          @addBill="submitForm(true)"
          @auditBill="auditForm"
          @getQuit="$router.go(-1)"
        >
          <template v-slot:specialDiyBtn>
            <el-button
              size="mini"
              :disabled="!options.billId"
              @click="handleEvent('exportFile')"
            >
              导出
            </el-button>
  
            <el-button size="mini" @click="handleEvent('batchUpdate')">
              批量修改
            </el-button>
            <el-button size="mini" @click="batchDel"> 批量删除 </el-button>
          </template>
        </topOperatingButton>
        <cardTitleCom
          cardTitle="基本信息"
          id="basicInformation"
          ref="basicInformation"
          :billStatus="options.billStatus"
        >
          <template slot="cardContent">
            <div class="x-f marT10">
              <el-form-item label="方案编号" required>
                <el-input
                  class="inputWidth"
                  size="mini"
                  :disabled="true"
                  v-model="options.billNo"
                  placeholder="方案编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="方案日期" required>
                <el-date-picker
                  class="inputWidth"
                  size="mini"
                  v-model="options.billDate"
                  type="date"
                  placeholder="方案日期"
                  :disabled="['2', '3'].includes(options.billStatus)"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="方案备注" prop="billRemark">
                <el-input
                  size="mini"
                  class="inputWidth"
                  v-model="options.billRemark"
                  type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 1 }"
                  :disabled="['2', '3'].includes(options.billStatus)"
                />
              </el-form-item>
            </div>
            <div class="x-f">
              <el-form-item label="默认充值金额" prop="fillMoney">
                <el-input
                  size="mini"
                  class="inputWidth"
                  v-model="options.fillMoney"
                  type="text"
                  placeholder="默认充值金额"
                  @input="
                    limitInputlength(options.fillMoney, options, 'fillMoney', true, 8, true)
                  "
                  :disabled="['2', '3'].includes(options.billStatus)"
                ></el-input>
              </el-form-item>
              <el-form-item label="默认赠送金额">
                <el-input
                  size="mini"
                  class="inputWidth"
                  v-model.number="options.presentMoney"
                  type="text"
                  placeholder="默认赠送金额"
                  @input="
                    limitInputlength(
                      options.presentMoney,
                      options,
                      'presentMoney',
                      true,
                      8,
                      true
                    )
                  "
                  :disabled="['2', '3'].includes(options.billStatus)"
                ></el-input>
              </el-form-item>
              <el-form-item label="实付金额" prop="factMoney">
                <span>{{ factMoney }}</span>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="会员充值列表" class="vipFillTable">
          <template slot="cardContent">
            <div class="table">
              <!-- 可编辑表格 -->
              <EditTable :options="options" @handleEvent="handleEvent" />
            </div>
          </template>
        </cardTitleCom>
      </el-form>
      <!-- 弹窗 -->
      <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
  </template>
  
  <script>
  import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
  import EditTable from "@/components/editTable"; //可编辑表格
  import Dialog from "@/components/Dialog";
  import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
  import {
    vipFillSave,
    vipFillUpdate,
    vipFillUpdateStatus,
    vipFillGetDetail,
  } from "@/api/vip/bill/fill";
  import { getBillNo } from "@/api/codeRule";
  import { uniqWith } from "lodash"; //去重
  import { limitInputlength, fcount } from "@/utils";
  import { getFormattedDate } from "@/utils/newDate";
  import { getTimeDate } from "@/utils/newDate/index.js"; //年月日时分秒
  
  export default {
    name: "FillDetail",
    components: {
      topOperatingButton,
      EditTable,
      cardTitleCom,
      Dialog,
    },
    watch: {
      "options.billStatus": {
        handler(val) {
          const disabledColumns = ["2", "3"].includes(val);
          this.options.columns[0].disabled = disabledColumns;
          this.options.columns[7].disabled = disabledColumns;
          this.options.columns[8].disabled = disabledColumns;
          this.options.columns[10].disabled = disabledColumns;
          this.options.status = disabledColumns;
        },
        immediate: true,
      },
    },
    data() {
      return {
        loading: false, //遮罩层
        //表格配置
        options: {
          fillMoney: "", // 充值金额
          presentMoney: "", // 赠送金额
          factMoney: "", // 实付金额
          billRemark: "", // 方案备注
          billDate: "", // 方案日期（当前日期）
          billNo: undefined, // 方案编号
          billId: undefined, //方案id
          billStatus: undefined,
          curListIndex: -1, //当前表格行
          mutiSelect: true, //是否多选
          list: [{}],
          check: [], //选中的数据
          curListIndex: -1, //当前表格下标
          tableCellLabel: "序号",
          tableHeight: 0, //表格高度
          status: undefined, //方案状态
          retainColumn: true, //保留表格最后一行
          tableIndex: 0,
          columns: [
            {
              prop: "vipNo",
              label: "会员卡号",
              type: "remoteSelect",
              click: "clickVip",
              minWidth: 200,
              align: "center",
              option: this.$select({
                key: "vip",
                option: {
                  option: {
                    label: "vipNo",
                    value: "vipNo",
                    modal: "vipNo",
                    change: (propValue, row) => {
                      if (row && this.options.tableIndex != undefined) {
                        const isRepetition = this.options.list.some(
                          (item) => item.vipId == row.vipId
                        );
                        if (!isRepetition) {
                          this.options.list[this.options.tableIndex];
                          this.$set(this.options.list, this.options.tableIndex, {
                            ...row,
                            remark: this.options.billRemark || "",
                            fillMoney: this.options.fillMoney ? this.options.fillMoney : "",
                            presentMoney: this.options.presentMoney
                              ? this.options.presentMoney
                              : "",
                            allFillMoney: fcount(
                              [
                                row.totalUsableMoney,
                                this.options.fillMoney,
                                this.options.presentMoney,
                              ],
                              "+"
                            ),
                          });
                        } else {
                          this.options.list[this.options.tableIndex] = {};
                          this.$message.error("请选择不同的会员");
                        }
                      }
                    },
                    tableChange: (propValue, row) => {
                      this.handleEvent("getSelectData", row);
                    },
                    buttons: [
                      {
                        type: "more",
                        option: {
                          title: "选择会员",
                          width: 1250,
                          type: "TreeAndTable",
                          formData: {
                            ...this.$dialog({ key: "vip" }),
                            table: {
                              ...this.$dialog({ key: "vip" }).table,
                              mutiSelect: true,
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              }).option,
              disabled: false,
              rules: true,
            },
            {
              prop: "vipName",
              label: "会员名称",
              minWidth: 150,
              align: "center",
            },
            {
              prop: "vipLevelNo",
              label: "级别编码",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "vipLevelName",
              label: "级别名称",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "tel",
              label: "手机",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "cardStatusName",
              label: "状态",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "totalUsableMoney",
              label: "总余额",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "fillMoney",
              label: "充值金额",
              minWidth: 120,
              align: "center",
              type: "input",
              input: "sumBalance",
              disabled: false,
              rules: true,
              isMinus: true,
            },
            {
              prop: "presentMoney",
              label: "赠送金额",
              minWidth: 120,
              align: "center",
              type: "input",
              input: "sumBalance",
              disabled: false,
              isMinus: true,
            },
            {
              prop: "allFillMoney",
              label: "充值后总余额",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "remark",
              label: "备注",
              minWidth: 120,
              align: "center",
              type: "input",
              disabled: false,
              notVerify: true,
            },
          ],
          summary: ["fillMoney", "presentMoney", "allFillMoney"],
        },
        //弹窗配置
        dialogOptions: {
          title: "选择会员",
          width: 1250,
          show: false,
          type: "TreeAndTable",
        },
        rules: {
          billNo: [
            {
              required: true,
              message: "请输入方案编号",
              trigger: ["blur", "change"],
            },
          ],
          billDate: [{ required: true, message: "请输入制单日期", trigger: "blur" }],
        },
        ceshi: undefined,
        pageH: 0, //页面高度
        btnsH: 0, //搜索框高度
        basicH: 0, //基础框高度
        paginationH: 50, //分页高度
      };
    },
    activated() {
      this.handleResize(); //自适应高度
      this.getDetail();
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
      this.getDetail();
    },
    beforeDestroy() {
      window.addEventListener("resize", this.handleResize);
    },
    created() {
      this.handleResize();
    },
    computed: {
      factMoney() {
        this.options.factMoney =
          this.options.list?.reduce(
            (moeny, j) => (moeny += Number(j?.fillMoney) || 0),
            0
          ) || 0;
        return this.options.factMoney;
      },
    },
    methods: {
      //保留小数位
      limitInputlength,
      //获取详情
      async getDetail() {
        this.options.billId = undefined;
        this.options.billStatus = undefined;
        if (this.$route.query.type === "Update" && this.$route.query.billId) {
          this.reset();
          this.loading = true;
          try {
            //获取方案详情
            const { data } = await vipFillGetDetail(this.$route.query.billId);
            this.options.columns[0].option.showItem = data.billDetailItems;
  
            //方案赋值
            this.options = {
              ...this.options,
              ...data,
              list: [],
            };
            this.options.list = data.billDetailItems.map((item) => ({
              ...item,
              totalUsableMoney: item.agoAllFillMoney,
            }));
          } catch (err) {}
          this.loading = false;
        } else {
          this.getBillno();
        }
      },
      //自适应获取高度
      async handleResize() {
        setTimeout(() => {
          this.pageH = document.getElementById("wrap")?.clientHeight || 0;
          this.btnsH = document.getElementById("topOperatingButton")?.clientHeight || 0;
          this.basicH = document.getElementById("basicInformation")?.clientHeight || 0;
          this.options.tableHeight =
            this.pageH - this.btnsH - this.basicH - this.paginationH - 68;
          console.log(this.pageH, this.btnsH, this.basicH, "金");
        }, 10);
      },
      async handleEvent(type, row) {
        //获取元素在数组的下标
        let contains = function (list, row) {
          var i = list.length;
          while (i--) {
            if (list[i] === row) {
              return i;
            }
          }
          return false;
        };
        switch (type) {
          case "clickVip":
            //保存当前表格行下标
            this.options.curListIndex = contains(this.options.list, row);
            this.dialogOptions.show = true;
            //弹窗配置
            this.dialogOptions = {
              title: "选择会员",
              width: 1250,
              show: true,
              type: "TreeAndTable",
              formData: this.$dialog({ key: "vip" }),
            };
            break;
          case "sumBalance":
            let index = contains(this.options.list, row);
            if (row.vipId) {
              //计算充值后总余额
              this.options.list[index].allFillMoney = fcount(
                [
                  fcount(
                    [
                      this.options.list[index].totalUsableMoney,
                      this.options.list[index].fillMoney,
                    ],
                    "+"
                  ),
                  this.options.list[index].presentMoney,
                ],
                "+"
              );
            }
            break;
          case "batchUpdate": //批量修改
            if (["2", "3"].includes(this.options.billStatus)) {
              return this.$message.warning("请操作未审核的方案");
            }
            if (!this.options.check.length) {
              return this.$message.warning("请选择要操作的数据项");
            }
            //弹窗配置
            this.dialogOptions = {
              title: "批量修改充值",
              width: 500,
              show: true,
              click: "getBatchUpdate",
              type: "VipBatchFill",
              data: {},
            };
            break;
          case "getBatchUpdate":
            const { fillMoney, presentMoney } = this.dialogOptions.formData;
            this.options.check.forEach((item) => {
              if (!item.vipId) return;
              this.$set(item, "fillMoney", fillMoney);
              this.$set(item, "presentMoney", presentMoney);
              let allFillMoney = fcount(
                [item.totalUsableMoney, item.fillMoney, item.presentMoney],
                "+"
              );
              this.$set(item, "allFillMoney", allFillMoney);
            });
            break;
          case "getSelectData":
            if (!row) return;
            let { check } = row;
            if (check.length <= 0) return;
            this.options.columns[0].option.showItem = list;
  
            let list = check.map((item) => ({
              ...item,
              remark: this.options.billRemark || "",
              fillMoney: this.options.fillMoney ? this.options.fillMoney : "",
              presentMoney: this.options.presentMoney ? this.options.presentMoney : "",
              allFillMoney: fcount(
                [item.totalUsableMoney, this.options.fillMoney, this.options.presentMoney],
                "+"
              ),
            }));
            let tableIndex = this.options.tableIndex;
            this.options.tableIndex = undefined;
            this.options.list.splice(tableIndex, 1, ...list);
            this.options.list = uniqWith(this.options.list, (x, y) => x.vipId == y.vipId);
            break;
          case "tableIndex":
            this.options.tableIndex = row;
            break;
          case "exportFile": // 导出
            this.download(
              "/api/system/vip/bill/vipFill/export",
              {
                billId: this.options.billId,
              },
              `会员充值导出${getTimeDate()}.xlsx`
            );
            break;
  
          default:
            break;
        }
      },
      // 提交保存
      async submitForm(isAdd) {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            console.log(this.options.billDate, "什么情况");
            let form = {
              billNo: this.options.billNo, // 方案号
              billId: this.options.billId, // 方案ID
              billDate: this.options.billDate,
              // ? this.options.billDate.split(' ')[0]
              // : '', // 方案时间
              billDetailItems: this.options.list, // 列表
              factMoney: this.options.factMoney,
              fillMoney: this.options.fillMoney,
              billStatus: this.options.billStatus,
              presentMoney: this.options.presentMoney,
              billRemark: this.options.billRemark,
            };
            form.billDetailItems = form.billDetailItems.filter((item) => item.vipId);
            if (form.billId) {
              try {
                const { data } = await vipFillUpdate(form);
                this.options = {
                  ...this.options,
                  billStatus: data.billStatus,
                };
                this.$message.success("修改成功");
                //新增
                if (isAdd) {
                  this.reset();
                  this.getBillno();
                }
              } catch (err) {}
            } else {
              try {
                const { data } = await vipFillSave(form);
                this.options = {
                  ...this.options,
                  billStatus: data.billStatus,
                  billId: data.billId,
                };
                this.$message.success("新增成功");
                //新增
                if (isAdd) {
                  this.reset();
                  this.getBillno();
                }
              } catch (err) {}
            }
            this.loading = false;
          } else this.$message.error("请把带有红星的必填项输入完整!");
        });
      },
      //初始化数据
      reset() {
        //表格配置
        this.options = {
          ...this.options,
          fillMoney: "", // 充值金额
          presentMoney: "", // 赠送金额
          factMoney: "", // 实付金额
          billRemark: "", // 方案备注
          billDate: "", // 方案日期（当前日期）
          billNo: undefined, // 方案编号
          billId: undefined, //方案id
          curListIndex: -1, //当前表格行
          mutiSelect: true, //是否多选
          list: [{}],
          check: [], //选中的数据
          curListIndex: -1, //当前表格下标
          tableCellLabel: "序号",
          tableHeight: 0, //表格高度
          status: undefined, //方案状态
          retainColumn: true, //保留表格最后一行
          tableIndex: 0,
          billStatus: undefined,
        };
        console.log(this.options, "options");
        //弹窗配置
        this.dialogOptions = {
          title: "选择会员",
          width: 1250,
          show: false,
          type: "TreeAndTable",
        };
        this.resetForm("form");
      },
      // 获取订单编号
      async getBillno() {
        this.options.billNo = await getBillNo(150201);
        this.options.billDate = getFormattedDate(2, "-");
      },
      // 审核/反审核  按钮
      async auditForm() {
        if (this.options.billStatus == "0") {
          this.loading = true;
          this.$confirm(`确定审核该方案`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              try {
                const { data } = await vipFillUpdateStatus({
                  billIds: [this.options.billId],
                  billStatus: 2,
                });
                this.options = {
                  ...this.options,
                  billStatus: data.billStatus,
                };
                this.$message.success(`审核成功`);
              } catch (err) {}
            })
            .catch(() => {});
          this.loading = false;
        } else {
          this.$message.warning("请操作未审核的方案");
        }
      },
      //批量删除
      batchDel() {
        if (["2", "3"].includes(this.options.billStatus)) {
          return this.$message.warning("请操作未审核的方案");
        }
        if (!this.options.check.length) {
          return this.$message.warning("请选择要操作的数据项");
        }
        this.$confirm("确定删除选中的数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let ids = [];
            this.options.check.forEach((item) => {
              if (item.vipId) ids.push(item.vipId);
            });
            if (ids.length >= 1) {
              this.options.list = this.options.list.filter(
                (item) => !ids.includes(item.vipId)
              );
              if (!this.options.list || this.options.list.length <= 0)
                this.options.list = [{}];
            } else this.$message.error("暂无可删除数据");
          })
          .catch(() => {});
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  //整体盒子
  .wrap {
    padding: 40px 10px 10px 10px;
    background-color: #eaeaea;
    height: calc(100vh - 84px);
  }
  //日期
  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 250px;
  }
  .table {
    padding: 10px;
  }
  </style>
  