<template>
  <!-- 添加会员弹窗 -->
  <c-dialog :title="title" :width="750" :showDialog.sync="open">
    <template #content>
      <el-form
        v-loading="detailLoading"
        ref="form"
        :model="vipForm"
        :rules="rules"
        label-width="80px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="会员卡号" prop="vipNo">
              <el-input
                class="pickerTime"
                v-model="vipForm.vipNo"
                placeholder="请输入会员卡号"
                maxlength="20"
                :disabled="vipForm.vipId"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="会员名称" prop="vipName">
              <el-input
                class="pickerTime"
                v-model="vipForm.vipName"
                placeholder="请输入会员名称"
                maxlength="11"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="tel">
              <el-input
                class="pickerTime"
                v-model.number="vipForm.tel"
                placeholder="请输入手机号"
                maxlength="11"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-select
                v-model="vipForm.sex"
                placeholder="请选择性别"
                filterable
                clearable
              >
                <el-option
                  v-for="dict in dict.type.sys_user_sex"
                  :key="dict.value"
                  :label="dict.label"
                  :value="parseInt(dict.value)"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="会员级别" prop="vipLevelId">
              <el-select
                v-model="vipForm.vipLevelId"
                filterable
                placeholder="请选择会员级别"
                clearable
                @change="changeVipLevel"
                :disabled="vipForm.vipId != undefined"
              >
                <el-option
                  v-for="item in vipCardTreeOptions"
                  :key="item.vipLevelId"
                  :label="item.vipLevelName"
                  :value="item.vipLevelId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="会员生日" prop="sortNo">
              <j-date-picker
                class="pickerTime"
                v-model="vipForm.birthday"
                id="value1"
                :width="width1"
                :placeholder="placeholder"
                :picker-options="pickerOptions"
                :rangeSeparator="rangeSeparator"
                :disabled="disabled"
                :showLunarClass="showLunarClass"
                :showLunarControl="showLunarControl"
                :type="type"
                :showBackYears="showBackYears"
                :showLunarIcon="showLunarIcon"
                :format="format"
                :isLunarProps="false"
                @lunarCalendar="calendarFunction"
                :disabled-dates="disableDates"
              >
              </j-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="生效日期" prop="begValidDate" key="begValidDate">
              <el-date-picker
                class="pickerTime"
                clearable
                v-model="vipForm.begValidDate"
                type="date"
                placeholder="请选择生效日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="结束日期" prop="endValidDate" key="endValidDate">
              <el-date-picker
                class="pickerTime"
                clearable
                v-model="vipForm.endValidDate"
                type="date"
                placeholder="请选择结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户地址" prop="regionId">
              <el-cascader
                filterable
                remote
                reserve-keyword
                :options="provinceList"
                :props="defaultProp"
                v-model="vipForm.regionId"
                placeholder="请选择地址"
                clearable
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="会员状态" prop="cardStatus">
              <el-select
                v-model="vipForm.cardStatus"
                placeholder="会员状态"
                filterable
                clearable
                :disabled="true"
              >
                <el-option
                  v-for="dict in dict.type.vip_card_status"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="vipForm.vipId && vipForm.shop_VIP_CONFIG_PASSWORD_12 == 'Y'">
          <el-col :span="12">
            <el-form-item label="会员密码">
              <div class="x-f">
                <el-input
                  class="pickerTime"
                  v-model="vipForm.usePassWord"
                  placeholder="请输入会员密码(6~18字符)"
                  maxlength="18"
                  disabled
                />
                <el-button
                  type="primary"
                  size="mini"
                  class="marL10"
                  @click="emptyPassWord"
                  >清空</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="信用额度" prop="usableCreditMoney">
              <el-input
                class="pickerTime"
                v-model.number="vipForm.usableCreditMoney"
                placeholder="请输入信用额度"
                @input="
                  limitInputlength(
                    vipForm.usableCreditMoney,
                    vipForm,
                    'usableCreditMoney',
                    true,
                    8,
                    true
                  )
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="详细地址" prop="regionAddress">
              <el-input
                class="remark"
                v-model="vipForm.regionAddress"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="备注" prop="remark">
              <el-input
                type="text"
                v-model="vipForm.remark"
                placeholder="备注长度介于 1 和 60 字符之间"
                maxlength="60"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template #buttonList>
      <el-button class="marL10" @click="cancel">取消</el-button>
      <el-button type="primary" class="marL10" @click="submitForm(false)"
        >保存并新增</el-button
      >
      <el-button type="primary" class="marL10" @click="submitForm(true)">保存</el-button>
    </template>
  </c-dialog>
</template>

<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { getAllRegion } from "@/api/goods/region"; //会员地址
import { addVipCard, updateVipCard } from "@/api/vip/base/vipCard"; //会员资料
import JDatePicker from "vue-jlunar-datepicker";
import { getBegDate, getEndDate } from "@/utils/validityPeriod";
import { limitInputlength } from "@/utils";
export default {
  name: "addVipCard",
  dicts: ["sys_user_sex", "vip_card_status"],
  components: {
    JDatePicker,
    CDialog,
  },
  data() {
    return {
      detailLoading: false, //表单加载
      replaceTime: "2023-02-26 00:00:00", //替换日期
      type: "DATE",
      showLunarClass: "LUNAR",
      showBackYears: 2,
      showLunarIcon: true,
      showLunarControl: true,
      width1: "100%", //这个设置成100%，原组件写死了px需要修改
      format: "yyyy-MM-dd",
      placeholder: "",
      rangeSeparator: "-",
      disabled: false,
      editable: true,
      clearable: true,
      pickerOptions: {
        //去掉禁止日期显示范围
        /* disabledDate (time) {
                return time.getTime() < Date.now() - 8.64e7;
              }*/
      },
      lunarTime: undefined, //农历日历
      provinceList: [], //全部行政地区
      defaultProp: {
        value: "id",
        label: "label",
        emitPath: false,
      }, //会员地址
      open: false, //新增对话框
      title: "新增会员", //对话框标题
      vipForm: {
        vipNo: null, //会员号
        vipName: null, //会员名称
        tel: null, //电话
        sex: null, //性别
        vipLevelId: null, //会员卡级别ID
        cardStatus: "0", //状态
        birthday: null, //出生日期
        isCnBirthday: null,
        cnBirthday: null, //农历生日
        begValidDate: null, //开始生效日期
        endValidDate: null, //结束生效日期
        regionId: null, //行政区ID
        regionAddress: null, //详细地址
        remark: null, //备注
      },

      rules: {
        vipNo: [
          {
            required: true,
            message: "会员卡号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        vipLevelId: [{ required: true, message: "会员卡等级不能为空", trigger: "input" }],
        tel: [
          {
            required: false,
            message: "手机号不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
          },
        ],
        begValidDate: [
          {
            required: true,
            message: "生效日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        endValidDate: [
          {
            required: true,
            message: "结束日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
      }, //表单校验规则
      vipCardTreeOptions: [], //会员等级
    };
  },
  created() {
    this.getTreeselect();
  },
  watch: {
    //侦听生日时间 判断是否农历
    "vipForm.birthday": {
      handler(newVal) {
        if (newVal) {
          this.vipForm.isCnBirthday = String(newVal).indexOf("L") != -1;
          if (!this.vipForm.isCnBirthday) {
            this.vipForm.cnBirthday = undefined;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    limitInputlength,
    //切换会员等级
    changeVipLevel(value) {
      const res = this.vipCardTreeOptions.find((item) => item.vipLevelId == value);
      this.vipForm.begValidDate = res?.begDate;
      this.vipForm.endValidDate = res?.endDate;
    },
    //生日禁用超过当前日期
    disableDates() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();

      const disabledDates = [];

      // 遍历超过当前日期的日期并添加到禁用列表中
      for (let year = currentYear; year <= 9999; year++) {
        for (let month = 0; month < 12; month++) {
          const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
          const maxDay =
            year === currentYear && month === currentMonth ? currentDay : lastDayOfMonth;

          for (let day = maxDay + 1; day <= lastDayOfMonth; day++) {
            disabledDates.push(new Date(year, month, day));
          }
        }
      }

      return disabledDates;
    },
    //对话框关闭前的回调
    handleClose(done) {
      if (
        this.vipForm.vipNo ||
        this.vipForm.vipName ||
        this.vipForm.tel ||
        this.vipForm.sex ||
        this.vipForm.vipLevelId ||
        this.vipForm.birthday ||
        this.vipForm.cnBirthday ||
        this.vipForm.regionId ||
        this.vipForm.regionAddress ||
        this.vipForm.remark
      ) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      } else {
        done();
      }
    },
    /** 查询分类下拉树结构 */
    getTreeselect() {
      getAllRegion().then((response) => {
        this.provinceList = response.data;
      }); //获取全部行政地区事件
    },
    // 表单重置
    reset() {
      this.vipForm = {
        vipNo: null, //会员号
        vipName: null, //会员名称
        tel: null, //电话
        sex: null, //性别
        vipLevelId: null, //会员卡级别ID
        cardStatus: "0", //状态
        birthday: null, //出生日期
        isCnBirthday: null, //是否过农历
        cnBirthday: null, //农历生日
        begValidDate: null, //开始生效日期
        endValidDate: null, //结束生效日期
        regionId: null, //行政区ID
        regionAddress: null, //详细地址
        remark: null, //备注
      };
      this.resetForm("form");
    },
    onDateChange(value) {
      this.vipForm.cnBirthday = value;
      // console.log(val)
    },
    //清空会员密码
    emptyPassWord() {
      if (!this.vipForm.usePassWord) return;
      this.vipForm.usePassWord = "";
      this.$message.success("清空会员密码成功");
    },
    /** 提交按钮 */
    submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //判断生效日期不能大于结束日期
          if (
            this.vipForm.begValidDate &&
            this.vipForm.endValidDate &&
            new Date(this.vipForm.begValidDate).getTime() >=
              new Date(this.vipForm.endValidDate).getTime()
          ) {
            return this.$message.error("会员结束日期小于生效日期，请修改日期。");
          }
          let birthday = this.vipForm.birthday;
          if (birthday && birthday.includes("L")) {
            birthday = birthday.replace("L", "");
          }
          if (this.vipForm.vipId != null) {
            await updateVipCard({
              ...this.vipForm,
              begValidDate: [":"].includes(this.vipForm.begValidDate)
                ? this.vipForm.begValidDate
                : this.vipForm.begValidDate + " 00:00:00",
              endValidDate: [":"].includes(this.vipForm.endValidDate)
                ? this.vipForm.endValidDate
                : this.vipForm.endValidDate + " 00:00:00",
              birthday,
            });
            this.$message.success("修改会员成功");
          } else {
            await addVipCard({
              ...this.vipForm,
              begValidDate: [":"].includes(this.vipForm.begValidDate)
                ? this.vipForm.begValidDate
                : this.vipForm.begValidDate + " 00:00:00",
              endValidDate: [":"].includes(this.vipForm.endValidDate)
                ? this.vipForm.endValidDate
                : this.vipForm.endValidDate + " 00:00:00",
              birthday,
            });
            this.$message.success("修改会员成功");
          }
          if (isAdd) {
            this.open = false;
          } else {
            this.reset();
          }
          this.$emit("getList");
        }
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 下拉框校验方法
    inputSelect() {
      this.$refs.form.validateField("vipLevelId");
    },
    //接受子传父农历
    calendarFunction(value) {
      this.vipForm.cnBirthday = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.pickerTime {
  width: 77%;
}
::v-deep .full-jcalendar__main {
  overflow-y: auto;
}
.el-form {
  margin: 15px 15px 0;
  .el-input,
  .el-select,
  .pickerTime,
  .el-cascader {
    width: 100%;
  }
}
</style>
