<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  vipFillListAPI,
  vipFillListSummaryAPI,
  cashierListAPI,
  cashierListSummaryAPI,
} from "@/api/vip/report/fillQuery"; //会员充值接口

import {
  vipFillQuery,
  vipFillQuerySummary,
  vipCardChangeQuery,
  vipScoreAdjustQuery,
  vipScoreAdjustQuerySummary,
  vipScoreExchangeQuery,
  vipScoreExchangeQuerySummary,
  vipStateChangeQuery,
  vipLevelChangeQuery,
} from "@/api/vip/bill/billQuery"; //会员充值接口

//业务类型枚举
let businessType = [
  { value: "0", label: "后台充值" },
  { value: "1", label: "前台POS充值" },
  { value: "2", label: "前台积分转充值" },
  { value: "3", label: "后台积分转充值" },
  { value: "4", label: "线上充值" },
  { value: "5", label: "门店换卡充值" },
  { value: "6", label: "导入储值" },
  { value: "8", label: "储零" },
];
export default {
  name: "fillQuery",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "会员充值查询",
        rowKey: "id",
        search: [
          {
            label: "单据时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "单据编号/级别编码/级别名称/会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
            hide: () => this.options?.tabColumnType == "vipBillFillQuery",
          },
          {
            type: "filters",
            tip: "单据编号/会员原卡号/会员原名称/会员卡号/会员名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "vipNos", label: "会员原卡号" },
              { filter: "vipNames", label: "会员原名称" },
              { filter: "newVipNos", label: "会员卡号" },
              { filter: "newVipNames", label: "会员名称" },
            ],
            hide: () => this.options?.tabColumnType == "vipBillCardChange",
          },
          {
            type: "filters",
            tip: "单据编号/会员卡号/会员名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
            ],
            hide: () =>
              this.options?.tabColumnType == "vipBillScoreAdjustQuery" ||
              this.options?.tabColumnType == "vipBillScoreExchangeQuery" ||
              this.options?.tabColumnType == "vipBillStateChangeQuery" ||
              this.options?.tabColumnType == "vipBillLevelChangeQuery",
          },
          {
            type: "input",
            label: "单据编号",
            model: "",
            filter: "billNo",
            seniorSearch: true,
            hide: () =>
              this.options?.tabColumnType == "vipBillCardChange" ||
              this.options?.tabColumnType == "vipBillScoreAdjustQuery" ||
              this.options?.tabColumnType == "vipBillScoreExchangeQuery" ||
              this.options?.tabColumnType == "vipBillStateChangeQuery" ||
              this.options?.tabColumnType == "vipBillLevelChangeQuery",
          },
          {
            type: "numberRange",
            label: "金额范围",
            filter: "scoreBegin",
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            filterFnc: ([one, two] = []) => ({
              beginFillMoney: one,
              endFillMoney: two,
            }),
            hide: () => this.options?.tabColumnType == "vipBillFillQuery",
          },
          this.$select({
            key: "viplevel",
            option: {
              hide: () => this.options?.tabColumnType == "vipBillFillQuery",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "vip",
            option: {
              hide: () => this.options?.tabColumnType == "vipBillFillQuery",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "shop",
            option: {
              hide: () => this.options?.tabColumnType == "vipBillFillQuery",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              hide: () => this.options?.tabColumnType == "vipBillFillQuery",
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "vipBillFillQuery",
        tabsColumns: [
          {
            title: "会员充值查询",
            type: "vipBillFillQuery",
            getListApi: vipFillQuery,
            getSummaryApi: vipFillQuerySummary,
            exportOption: {
              exportApi: "vipBillFillQuery",
              exportName: "会员充值查询",
            },
            columns: [
              {
                prop: "billNo",
                label: "充值小票号",
                minWidth: 180,
              },
              {
                prop: "fillTime",
                label: "单据时间",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "tel",
                label: "手机号",
                minWidth: 130,
              },
              {
                prop: "cardStatusName",
                label: "卡状态",
                minWidth: 100,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessScore",
                label: "赠送积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "endMoney",
                label: "充值后余额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "fillMoneyTotal",
                label: "充值总额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "billRemark",
                label: "备注",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payTypes",
                type: "slots",
                align: "center",
                formatter: () => "",
                label: "收款方式",
                minWidth: 300,
                children: () =>
                  this.options.list?.[0]?.payTypes
                    ?.map?.((x) => ({
                      ...x,
                      label: x.payModeName,
                      prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                      minWidth: 110,
                      summary: true,
                      getValue: (row, prop) =>
                        row.payDetailList?.find((y) => y.payModeId === x.payModeId)
                          ?.payMoney || 0,
                    }))
                    .sort((a, b) => b.payModeId - a.payModeId) || [],
              },
              {
                prop: "fillTypeName",
                label: "充值类型",
                minWidth: 150,
              },
            ],
            summary: [
              "fillMoney",
              "largessMoney",
              "largessScore",
              "endMoney",
              "fillMoneyTotal",
            ],
          },
          {
            title: "会员换卡查询",
            type: "vipBillCardChange",
            getListApi: vipCardChangeQuery,
            exportOption: {
              exportApi: "vipBillCardChange",
              exportName: "会员换卡查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "单据时间",
                minWidth: 80,
              },
              {
                prop: "billNo",
                label: "单据编号",
                minWidth: 180,
              },
              {
                prop: "cardStatusName",
                label: "会员卡原状态",
                minWidth: 80,
              },
              {
                prop: "newCardStatusName",
                label: "会员卡状态",
                minWidth: 80,
              },
              {
                prop: "vipNo",
                label: "会员原卡号",
                minWidth: 80,
              },
              {
                prop: "newVipNo",
                label: "会员卡号",
                minWidth: 80,
              },
              {
                prop: "vipName",
                label: "会员原名称",
                minWidth: 80,
              },
              {
                prop: "newVipName",
                label: "会员名称",
                minWidth: 80,
              },
            ],
          },
          {
            title: "会员积分调整查询",
            type: "vipBillScoreAdjustQuery",
            getListApi: vipScoreAdjustQuery,
            getSummaryApi: vipScoreAdjustQuerySummary,
            exportOption: {
              exportApi: "vipBillScoreAdjustQuery",
              exportName: "会员积分调整查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "单据时间",
                minWidth: 150,
              },
              {
                prop: "billNo",
                label: "单据编号",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "adjustScore",
                label: "调整积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agoUsableScore",
                label: "调整前可用积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "usableScore",
                label: "调整后可用积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agoAllScore",
                label: "调整前累计积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "allScore",
                label: "调整后累计积分",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: [
              "adjustScore",
              "agoAllScore",
              "agoUsableScore",
              "allScore",
              "usableScore",
            ],
          },
          {
            title: "会员积分兑换查询",
            type: "vipBillScoreExchangeQuery",
            getListApi: vipScoreExchangeQuery,
            getSummaryApi: vipScoreExchangeQuerySummary,
            exportOption: {
              exportApi: "vipBillScoreExchangeQuery",
              exportName: "会员积分兑换查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "单据时间",
                minWidth: 150,
              },
              {
                prop: "billNo",
                label: "单据编号",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "商品数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "equalScore",
                label: "兑换积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "equalScoreTotal",
                label: "兑换总积分",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["unitQty", "equalScore", "equalScoreTotal"],
          },
          {
            title: "会员状态调整查询",
            type: "vipBillStateChangeQuery",
            getListApi: vipStateChangeQuery,
            exportOption: {
              exportApi: "vipBillStateChangeQuery",
              exportName: "会员状态调整查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "单据时间",
                minWidth: 150,
              },
              {
                prop: "billNo",
                label: "单据编号",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "cardStatusName",
                label: "会员原状态",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "cardNewStatusName",
                label: "会员状态",
                minWidth: 120,
                sortable: true,
              },
            ],
          },
          {
            title: "会员更换级别查询",
            type: "vipBillLevelChangeQuery",
            getListApi: vipLevelChangeQuery,
            exportOption: {
              exportApi: "vipBillLevelChangeQuery",
              exportName: "会员更换级别查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "单据时间",
                minWidth: 150,
              },
              {
                prop: "billNo",
                label: "单据编号",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "会员原级别",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "newVipLevelName",
                label: "会员级别",
                minWidth: 120,
                sortable: true,
              },
            ],
          },
        ],
      },
      payTypes: [], //付款方式
    };
  },
  watch: {
    options: {
      handler(val) {
        console.log(val, "valu");
      },
      deep: true,
    },
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "getListed":
          if (this.options.list?.length && this.options.tabColumnType === "vipFillList") {
            this.payTypes = this.options.list[0].payTypes;
            this.options.summary = [
              "fillMoney",
              "largessMoney",
              ...this.payTypes.map(
                (x) => `payDetailList.${x.payModeId}.payModeId.payMoney`
              ),
            ];
          }
          break;
        case "sortChange":
          this.options.defaultBody = {
            ...this.options.defaultBody,
            orderStr: row.prop,
            orderSort: row.order,
          };
          this.$refs.tablePage.getList();
          break;
        default:
          break;
      }
    },
  },
};
</script>
