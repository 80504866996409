var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.fillRuleForm,
            rules: _vm.rules,
            "label-width": "96px",
          },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isExamineBtn: true,
              showAudit: false,
              isStopBtn: true,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditBill,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
              isStop: _vm.getEnable,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                id: "basicInformation",
                billStatus: _vm.fillRuleForm.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10 baseInfo" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "billNo", label: "方案编号" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: { disabled: true, placeholder: "方案编号" },
                          model: {
                            value: _vm.fillRuleForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.fillRuleForm, "billNo", $$v)
                            },
                            expression: "fillRuleForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "billRuleName", label: "方案名称" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "方案名称(1-40字符)",
                            maxlength: "40",
                          },
                          model: {
                            value: _vm.fillRuleForm.billRuleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.fillRuleForm, "billRuleName", $$v)
                            },
                            expression: "fillRuleForm.billRuleName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "优先级" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "discountUpSelect",
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.fillRuleForm.discountPriority1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm,
                                  "discountPriority1",
                                  $$v
                                )
                              },
                              expression: "fillRuleForm.discountPriority1",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.vip_discount_priority,
                            function (dict) {
                              return _c("el-option", {
                                key: dict.value,
                                attrs: { label: dict.label, value: dict.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "greater" }, [_vm._v(">")]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "discountPriority1",
                          "label-width": "0",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "discountUpSelect",
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.fillRuleForm.discountPriority2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm,
                                  "discountPriority2",
                                  $$v
                                )
                              },
                              expression: "fillRuleForm.discountPriority2",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.vip_discount_priority,
                            function (dict) {
                              return _c("el-option", {
                                key: dict.value,
                                attrs: { label: dict.label, value: dict.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案备注" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                          },
                          model: {
                            value: _vm.fillRuleForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.fillRuleForm, "billRemark", $$v)
                            },
                            expression: "fillRuleForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "会员级别折扣" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "discountBody" },
                  [
                    _vm._l(
                      _vm.fillRuleForm.vipLevelItems,
                      function (item, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "x-f addLevelItem" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "marR15",
                                  staticStyle: {
                                    width: "64px",
                                    display: "inline-block",
                                  },
                                },
                                [_vm._v("会员级别")]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "0px",
                                    prop:
                                      "vipLevelItems." + index + ".vipLevelId",
                                    rules: {
                                      required: true,
                                      message: "请选择会员级别",
                                      trigger: ["change", "blur"],
                                    },
                                  },
                                },
                                [
                                  _c("SelectRemote", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      option: _vm.vipLevelOption.option,
                                    },
                                    on: { handleEvent: _vm.handleEvent },
                                    model: {
                                      value: item.vipLevelId,
                                      callback: function ($$v) {
                                        _vm.$set(item, "vipLevelId", $$v)
                                      },
                                      expression: "item.vipLevelId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "marR15 marL15" }, [
                                _vm._v("折扣(%)"),
                              ]),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "0px",
                                    prop:
                                      "vipLevelItems." +
                                      index +
                                      ".discountRate",
                                    rules: {
                                      required: true,
                                      message: "请输入折扣",
                                      trigger: ["change", "blur"],
                                    },
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    staticClass: "inputWidth",
                                    attrs: {
                                      "step-strictly": "",
                                      disabled: _vm.disabled,
                                      controls: false,
                                      min: 0,
                                      max: 100,
                                    },
                                    model: {
                                      value: item.discountRate,
                                      callback: function ($$v) {
                                        _vm.$set(item, "discountRate", $$v)
                                      },
                                      expression: "item.discountRate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    type: "text",
                                    size: "small",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.deleteRowVipLevel(
                                        index,
                                        _vm.fillRuleForm.vipLevelItems
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "79px",
                                "margin-bottom": "5px",
                              },
                            },
                            [
                              _c("EditTable", {
                                attrs: { options: item.discountOptions },
                              }),
                            ],
                            1
                          ),
                        ])
                      }
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        staticClass: "marT15 addLevel",
                      },
                      [
                        _c("span", { on: { click: _vm.GetAddLevel } }, [
                          _vm._v(" +级别 "),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用范围", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "bodyRange" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "适用渠道",
                          "label-width": "80px",
                          prop: "AllowOfflineShop",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.fillRuleForm.AllowOfflineShop,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm,
                                  "AllowOfflineShop",
                                  $$v
                                )
                              },
                              expression: "fillRuleForm.AllowOfflineShop",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "0", disabled: _vm.disabled } },
                              [_vm._v("线下门店 ")]
                            ),
                            _c(
                              "el-checkbox",
                              { attrs: { label: "1", disabled: _vm.disabled } },
                              [_vm._v("线上商城 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "适用时间", "label-width": "80px" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.fillRuleForm.useTimeRangeType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm,
                                  "useTimeRangeType",
                                  $$v
                                )
                              },
                              expression: "fillRuleForm.useTimeRangeType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.use_time_range_type,
                            function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: dict.value },
                                },
                                [_vm._v(_vm._s(dict.label))]
                              )
                            }
                          ),
                          1
                        ),
                        _vm.fillRuleForm.useTimeRangeType === "2"
                          ? _c(
                              "div",
                              { staticClass: "range" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEvent(
                                          "openTimeDateDialog"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("选择时间")]
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("EditTable", {
                                      attrs: { options: _vm.dateOptions },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "适用门店", "label-width": "80px" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.fillRuleForm.shopRangeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.fillRuleForm, "shopRangeType", $$v)
                              },
                              expression: "fillRuleForm.shopRangeType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.fill_shop_range_type,
                            function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: dict.value },
                                },
                                [_vm._v(_vm._s(dict.label))]
                              )
                            }
                          ),
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fillRuleForm.shopRangeType == "2" ||
                                  _vm.fillRuleForm.shopRangeType == "1",
                                expression:
                                  "\n                fillRuleForm.shopRangeType == '2' || fillRuleForm.shopRangeType == '1'\n              ",
                              },
                            ],
                            staticClass: "range",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent("openShopDialog")
                                  },
                                },
                              },
                              [_vm._v("指定门店资料")]
                            ),
                            _c(
                              "div",
                              [
                                _c("EditTable", {
                                  attrs: { options: _vm.shopOptions },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "适用商品", "label-width": "80px" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.fillRuleForm.goodsRangeType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm,
                                  "goodsRangeType",
                                  $$v
                                )
                              },
                              expression: "fillRuleForm.goodsRangeType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.vip_goods_range_type,
                            function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: dict.value },
                                },
                                [_vm._v(_vm._s(dict.label))]
                              )
                            }
                          ),
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fillRuleForm.goodsRangeType == "2" ||
                                  _vm.fillRuleForm.goodsRangeType == "1",
                                expression:
                                  "\n                fillRuleForm.goodsRangeType == '2' || fillRuleForm.goodsRangeType == '1'\n              ",
                              },
                            ],
                            staticClass: "range",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent("openGoodsDialog")
                                  },
                                },
                              },
                              [_vm._v("指定商品")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent("openBrandDialog")
                                  },
                                },
                              },
                              [_vm._v("指定品牌")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent("openCategoryDialog")
                                  },
                                },
                              },
                              [_vm._v("指定类别")]
                            ),
                            _c(
                              "div",
                              [
                                _c("EditTable", {
                                  attrs: { options: _vm.goodsOptions },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }