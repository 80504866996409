<template>
  <!--批量新增会员弹窗 -->
  <c-dialog :title="title" :width="900" :showDialog.sync="open">
    <template #content>
      <el-form
        v-loading="detailLoading"
        ref="form"
        :model="batchVipForm"
        :rules="rules"
        label-width="80px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="起始卡号" prop="cardNo">
              <el-input
                class="pickerTime"
                v-model="batchVipForm.cardNo"
                placeholder="输入起始卡号"
                maxlength="20"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制卡数量" prop="cardQty">
              <el-input
                class="pickerTime"
                v-model.number="batchVipForm.cardQty"
                placeholder="请输入制卡数量"
                maxlength="30"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="步长" prop="step" key="step">
              <el-input-number
                v-model="batchVipForm.step"
                controls-position="right"
                :min="1"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="卡号前缀" prop="cardPrefix">
              <el-input
                class="pickerTime"
                v-model="batchVipForm.cardPrefix"
                placeholder="请输入卡号前缀"
                maxlength="8"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卡号后缀" prop="cardSuffix">
              <el-input
                class="pickerTime"
                v-model="batchVipForm.cardSuffix"
                placeholder="请输入卡号后缀"
                maxlength="8"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="会员姓名" prop="cardName">
              <el-input
                class="pickerTime"
                v-model="batchVipForm.cardName"
                placeholder="输入会员姓名"
                maxlength="10"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="会员级别" prop="vipLevelId">
              <el-select
                v-model="vipLevelId"
                filterable
                clearable
                placeholder="请选择会员级别"
                @change="changeVipLevel($event)"
              >
                <el-option
                  v-for="item in vipCardTreeOptions"
                  :key="item.vipLevelId"
                  :label="item.vipLevelName"
                  :value="`${item.vipLevelId}|${item.vipLevelName}`"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生效日期" prop="begValidDate" key="begValidDate">
              <el-date-picker
                clearable
                v-model="batchVipForm.begValidDate"
                type="datetime"
                placeholder="请选择生效日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束日期" prop="endValidDate" key="endValidDate">
              <el-date-picker
                clearable
                v-model="batchVipForm.endValidDate"
                type="datetime"
                placeholder="请选择结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="初始密码" prop="usePassWord">
              <el-input
                class="pickerTime"
                v-model="batchVipForm.usePassWord"
                placeholder="请输入初始密码"
                maxlength="16"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="信用额度" prop="usableCreditMoney">
              <el-input
                class="pickerTime"
                v-model.number="batchVipForm.usableCreditMoney"
                placeholder="请输入信用额度"
                @input="
                  limitInputlength(
                    batchVipForm.usableCreditMoney,
                    batchVipForm,
                    'usableCreditMoney',
                    true,
                    8,
                    true
                  )
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="是否跳过重复卡号"
              prop="isCheckRepeat"
              label-width="140px"
            >
              <el-radio-group v-model="batchVipForm.isCheckRepeat">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="过滤数字" prop="filterNumber">
            <el-checkbox-group v-model="batchVipForm.filterNumber">
              <el-checkbox :label="item" v-for="item in checkList" :key="item" name="type"
                >{{ item }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-row>
        <div class="btns">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="getCardNumber"
            >生成卡号</el-button
          >
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="batchDelete"
            >删除卡号</el-button
          >
        </div>
        <el-row>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            class="batchTable"
            :data="tableData"
            border
            max-height="500"
            height="300"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column
              show-overflow-tooltip
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              show-overflow-tooltip
              label="会员卡号"
              align="center"
              prop="cardNo"
            />
            <el-table-column
              show-overflow-tooltip
              label="会员姓名"
              align="center"
              prop="cardName"
            />
            <el-table-column show-overflow-tooltip label="会员级别" align="center">
              <template>
                <span>{{ vipLevelName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="生效时间"
              align="center"
              prop="begValidDate"
            />
            <el-table-column
              show-overflow-tooltip
              label="结束日期"
              align="center"
              prop="endValidDate"
            />
          </el-table>
        </el-row>
      </el-form>
    </template>
    <template #buttonList>
      <el-button size="mini" class="marL10" @click="cancel">取 消</el-button>
      <el-button size="mini" type="primary" class="marL10" @click="submitForm"
        >确 定</el-button
      >
    </template>
  </c-dialog>
</template>

<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { batchVipCard, batchCardNumber } from "@/api/vip/base/vipCard"; //会员资料
import { getBegDate, getEndDate } from "@/utils/validityPeriod";
import { limitInputlength } from "@/utils";
export default {
  name: "batchVipCard",
  components: { CDialog },
  data() {
    return {
      selections: [], //选中数组
      vipLevelId: undefined,
      vipLevelName: "", //会员卡级别名称
      tableData: [], //单号表格
      checkList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], //所有数据
      loading: false, //表格加载
      detailLoading: false, //表单加载
      open: false, //新增对话框
      title: "新增会员", //对话框标题
      batchVipForm: {
        isCheckRepeat: false, //是否跳过重复卡号
        cardNo: undefined, //起始卡号
        cardQty: undefined, //制卡数量
        cardPrefix: undefined, //卡前缀
        cardSuffix: undefined, //卡后缀
        vipName: undefined, //会员姓名
        vipLevelId: undefined, //会员卡级别ID
        begValidDate: undefined, //开始生效日期
        endValidDate: undefined, //结束生效日期
        usePassWord: undefined, //初始密码
        step: 1, //步长
        filterNumber: [], //过滤
      },

      rules: {
        cardNo: [
          {
            required: true,
            message: "起始卡号不能为空",
            trigger: ["blur", "change"],
          },
          { pattern: /^[0-9]{1,20}$/, message: "请输入1 ~ 20个数值类型的字符" },
        ],
        cardQty: [
          {
            required: true,
            message: "制卡数量不能为空",
            trigger: ["blur", "change"],
          },
          { pattern: /^[0-9]{1,6}$/, message: "请输入1 ~ 6个数值类型的字符" },
        ],

        vipLevelId: [
          { required: true, message: "会员卡等级不能为空", trigger: "change" },
        ],
      }, //表单校验规则
      vipCardTreeOptions: [], //会员等级
    };
  },

  methods: {
    limitInputlength,
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selections = selection;
      this.name = selection.map((item) => item.cardName);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    //卡号前段删除
    batchDelete() {
      console.log(this.selections);
      if (this.selections.length > 0) {
        this.$confirm(`确定删除选中数据?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            for (let i = 0; i < this.selections.length; i++) {
              for (let y = 0; y < this.tableData.length; y++) {
                if (this.tableData[y] == this.selections[i]) {
                  this.tableData.splice(y, 1);
                  break;
                }
              }
            }
          })
          .catch(() => {});
      } else {
        this.$message({ message: "请选择要删除的会员", type: "warning" });
        return false;
      }
    },
    changeVipLevel(val) {
      let [vipLevelId, vipLevelName] = val.split("|");
      this.batchVipForm.vipLevelId = vipLevelId;
      this.vipLevelName = vipLevelName;
      const res = this.vipCardTreeOptions.find((item) => item.vipLevelId == vipLevelId);
      this.batchVipForm.begValidDate = res?.begDate;
      this.batchVipForm.endValidDate = res?.endDate;
    },
    //对话框关闭前的回调
    handleClose(done) {
      if (
        this.batchVipForm.cardNo ||
        this.batchVipForm.cardQty ||
        this.batchVipForm.cardPrefix ||
        this.batchVipForm.cardSuffix ||
        this.batchVipForm.vipName ||
        this.batchVipForm.vipLevelId ||
        this.batchVipForm.usePassWord ||
        this.batchVipForm.filterNumber.length > 0
      ) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      } else {
        done();
      }
    },
    // 表单重置
    reset() {
      this.batchVipForm = {
        isCheckRepeat: false, //是否跳过重复卡号
        cardNo: undefined, //起始卡号
        cardQty: undefined, //制卡数量
        cardPrefix: undefined, //卡前缀
        cardSuffix: undefined, //卡后缀
        vipName: undefined, //会员姓名
        vipLevelId: undefined, //会员卡级别ID
        begValidDate: undefined, //开始生效日期
        endValidDate: undefined, //结束生效日期
        usePassWord: undefined, //初始密码
        usableCreditMoney: undefined,
        step: 1, //步长
        filterNumber: [], //过滤
      };
      this.vipLevelId = undefined;
      this.tableData = [];
      this.resetForm("form");
      // 初始化查询，默认为当天
      // this.batchVipForm.begValidDate = getBegDate()
      // 初始化查询，默认结束时间十年后。
      // this.batchVipForm.endValidDate = getEndDate()
    },
    //生成卡号
    getCardNumber() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          batchCardNumber(this.batchVipForm).then((response) => {
            this.$modal.msgSuccess("生成卡号成功");
            this.tableData = response.data;
          });
        }
      });
    },
    /** 提交按钮 */
    submitForm() {
      const obj = {
        cardInfos: this.tableData,
      };
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //判断生效日期不能大于结束日期
          if (
            this.batchVipForm.begValidDate &&
            this.batchVipForm.endValidDate &&
            new Date(this.batchVipForm.begValidDate).getTime() >=
              new Date(this.batchVipForm.endValidDate).getTime()
          ) {
            return this.$message.error("会员结束日期小于生效日期，请修改日期。");
          }
          batchVipCard(obj).then((response) => {
            this.$modal.msgSuccess("批量新增成功");
            this.open = false;
            this.$emit("getList");
          });
        }
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.batchTable {
  margin-top: 1rem;
  width: 100%;
}
.pickerTime {
  width: 97%;
}
.el-form {
  margin: 15px 15px 0;
  .el-input,
  .el-select,
  .el-input-number {
    width: 100%;
  }
}
.btns {
  background-color: #f8f8f9;
  padding: 5px 0;
}
</style>
