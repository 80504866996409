<template>
  <div class="wrap x-f" id="wrap">
    <cardTitleCom cardTitle="会员账本" class="vipLedger">
      <template slot="cardContent">
        <div class="padd10">
          <el-table
            :data="tableData"
            tooltip-effect="dark"
            border
            v-loading="loadingTable"
            ref="multipleTable"
            show-summary
            :summary-method="getSummaries"
            :max-height="tableHeight"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column label="序号" align="center" width="60" type="index" />
            <el-table-column
              v-for="(item, index) in tableColumn"
              :key="index"
              :label="item.label"
              align="center"
              :prop="item.prop"
              :minWidth="item.minWidth"
            />
          </el-table>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { fcount, isObject, isNumber } from "@/utils";
import { vipScoreBook } from "@/api/vip/base/vipCard";
export default {
  name: "VipScoreBook",
  components: {
    cardTitleCom,
  },
  data() {
    return {
      summary: ["score", "endScore"],
      tableData: [],
      vipId: undefined,
      loadingTable: false,
      tableColumn: [
        {
          label: "日期",
          prop: "createTime",
          minWidth: 100,
        },
        {
          label: "小票号",
          prop: "billNo",
          minWidth: 100,
        },
        {
          label: "类型",
          prop: "scoreTypeName",
          minWidth: 120,
        },
        {
          label: "积分",
          prop: "score",
          minWidth: 80,
        },
        {
          label: "剩余积分",
          prop: "endScore",
          minWidth: 80,
        },
        {
          label: "门店",
          prop: "shopName",
          minWidth: 100,
        },
        {
          label: "收银员",
          prop: "cashierName",
          minWidth: 100,
        },
        {
          label: "备注",
          prop: "remark",
          minWidth: 160,
        },
      ],
      vivwH: 0, //页面高度
    };
  },
  created() {
    if (this.$route.query.vipId) {
      this.vipId = this.$route.query.vipId;
      this.getList();
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
    });
  },
  computed: {
    tableHeight() {
      return this.vivwH - 70;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getSummaries({ columns, data } = {}) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const sumItem = this.summary?.find((x) =>
          isObject(x) ? x.key === column.property : x === column.property
        );
        if (sumItem) {
          const arr = column.property.split(".");
          let values = data.map((x) => Number(x[column.property] || 0));
          if (arr?.length === 4) {
            const [p, i, key, s] = arr;
            values = data.map((x) => x[p]?.find?.((x) => String(x[key]) === i)?.[s] || 0);
          }
          sums[index] = `${values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          )}${isObject(sumItem) ? ` ${sumItem.unit}` : ""}`;
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    async getList() {
      try {
        if (this.vipId) {
          this.loadingTable = true;
          const res = await vipScoreBook({ vipId: this.vipId });
          this.tableData = res.data || [];
        } else {
          this.$message.error("请先选择会员");
        }
      } catch (error) {
      } finally {
        this.loadingTable = false;
      }
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  width: 100%;
  .vipLedger {
    width: 100%;
    height: 100%;
  }
}
</style>
