<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="fillRuleForm" :rules="rules" label-width="96px">
      <topOperatingButton
        :isExamineBtn="true"
        :showAudit="false"
        :isStopBtn="true"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditBill"
        @getQuit="$router.go(-1)"
        @isStop="getEnable"
        id="topOperatingButton"
      >
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="fillRuleForm.billStatus"
      >
        <template slot="cardContent">
          <div class="x-f marT10 baseInfo">
            <el-form-item prop="billNo" label="方案编号">
              <el-input
                class="inputWidth"
                :disabled="true"
                v-model="fillRuleForm.billNo"
                placeholder="方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="billRuleName" label="方案名称">
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="fillRuleForm.billRuleName"
                placeholder="方案名称(1-40字符)"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item label="优先级">
              <el-select
                class="discountUpSelect"
                v-model="fillRuleForm.discountPriority1"
                :disabled="disabled"
              >
                <el-option
                  v-for="dict in dict.type.vip_discount_priority"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
            <span class="greater">></span>
            <el-form-item prop="discountPriority1" label-width="0">
              <el-select
                class="discountUpSelect"
                v-model="fillRuleForm.discountPriority2"
                :disabled="disabled"
              >
                <el-option
                  v-for="dict in dict.type.vip_discount_priority"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="方案备注">
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="fillRuleForm.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="会员级别折扣">
        <template slot="cardContent">
          <div class="discountBody">
            <div v-for="(item, index) in fillRuleForm.vipLevelItems" :key="index">
              <div class="x-f addLevelItem">
                <span class="marR15" style="width: 64px; display: inline-block"
                  >会员级别</span
                >
                <el-form-item
                  label-width="0px"
                  :prop="'vipLevelItems.' + index + '.vipLevelId'"
                  :rules="{
                    required: true,
                    message: '请选择会员级别',
                    trigger: ['change', 'blur'],
                  }"
                >
                  <SelectRemote
                    :disabled="disabled"
                    v-model="item.vipLevelId"
                    :option="vipLevelOption.option"
                    @handleEvent="handleEvent"
                  />
                </el-form-item>
                <span class="marR15 marL15">折扣(%)</span>
                <el-form-item
                  label-width="0px"
                  :prop="'vipLevelItems.' + index + '.discountRate'"
                  :rules="{
                    required: true,
                    message: '请输入折扣',
                    trigger: ['change', 'blur'],
                  }"
                >
                  <el-input-number
                    class="inputWidth"
                    v-model="item.discountRate"
                    step-strictly
                    :disabled="disabled"
                    :controls="false"
                    :min="0"
                    :max="100"
                  />
                </el-form-item>
                <el-button
                  :disabled="disabled"
                  @click.native.prevent="
                    deleteRowVipLevel(index, fillRuleForm.vipLevelItems)
                  "
                  type="text"
                  size="small"
                >
                  删除
                </el-button>
              </div>
              <div style="margin-left: 79px; margin-bottom: 5px">
                <!-- 可编辑表格 -->
                <EditTable :options="item.discountOptions" />
              </div>
            </div>
            <div class="marT15 addLevel" v-show="!disabled">
              <span @click="GetAddLevel"> +级别 </span>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用范围" id="basicInformation">
        <template slot="cardContent">
          <div class="bodyRange">
            <el-form-item label="适用渠道" label-width="80px" prop="AllowOfflineShop">
              <el-checkbox-group v-model="fillRuleForm.AllowOfflineShop">
                <el-checkbox label="0" :disabled="disabled">线下门店 </el-checkbox>
                <el-checkbox label="1" :disabled="disabled">线上商城 </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="适用时间" label-width="80px">
              <el-radio-group
                v-model="fillRuleForm.useTimeRangeType"
                :disabled="disabled"
              >
                <el-radio
                  v-for="dict in dict.type.use_time_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
              <div class="range" v-if="fillRuleForm.useTimeRangeType === '2'">
                <el-button @click="handleEvent('openTimeDateDialog')" :disabled="disabled"
                  >选择时间</el-button
                >
                <div>
                  <!-- 可编辑表格 -->
                  <EditTable :options="dateOptions" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="适用门店" label-width="80px">
              <el-radio-group v-model="fillRuleForm.shopRangeType" :disabled="disabled">
                <el-radio
                  v-for="dict in dict.type.fill_shop_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
              <div
                class="range"
                v-show="
                  fillRuleForm.shopRangeType == '2' || fillRuleForm.shopRangeType == '1'
                "
              >
                <el-button @click="handleEvent('openShopDialog')" :disabled="disabled"
                  >指定门店资料</el-button
                >
                <div>
                  <!-- 可编辑表格 -->
                  <EditTable :options="shopOptions" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="适用商品" label-width="80px">
              <el-radio-group v-model="fillRuleForm.goodsRangeType" :disabled="disabled">
                <el-radio
                  v-for="dict in dict.type.vip_goods_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>

              <div
                class="range"
                v-show="
                  fillRuleForm.goodsRangeType == '2' || fillRuleForm.goodsRangeType == '1'
                "
              >
                <el-button @click="handleEvent('openGoodsDialog')" :disabled="disabled"
                  >指定商品</el-button
                >

                <el-button @click="handleEvent('openBrandDialog')" :disabled="disabled"
                  >指定品牌</el-button
                >

                <el-button @click="handleEvent('openCategoryDialog')" :disabled="disabled"
                  >指定类别</el-button
                >
                <div>
                  <!-- 可编辑表格 -->
                  <EditTable :options="goodsOptions" />
                </div>
              </div>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import EditTable from "@/components/editTable"; //可编辑表格
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import {
  updateOrderStatus,
  getOrderDetail,
  addOrder,
  updateOrder,
  updateEnable,
} from "@/api/vip/base/fillRule"; //会员规则
import { getBillNo } from "@/api/codeRule"; //会员充值规则号
import { listlevel } from "@/api/vip/base/level.js"; //会员级别
import { uniqWith } from "lodash"; //去重
import { getDateChangeDetail } from "@/utils/public"; //适用时间转换
export default {
  name: "discountRuleDetail",
  dicts: [
    "fill_shop_range_type",
    "use_time_range_type",
    "vip_discount_priority",
    "vip_goods_range_type",
    "vip_applicable_commodities_appoint_type",
  ],
  components: {
    topOperatingButton,
    cardTitleCom,
    SelectRemote,
    EditTable,
    Dialog,
  },
  data() {
    return {
      //会员级别数据
      vipLevelOption: this.$select({
        key: "viplevel",
        option: {
          option: {
            change: (propValue, row, option) => {},
            buttons: [
              {
                type: "more",
                click: "getVipLevel",
                option: {
                  title: "选择会员级别",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: {
                    ...this.$dialog({ key: "vipLevel" }),
                    table: {
                      ...this.$dialog({ key: "vipLevel" }).table,
                      radioSelect: true,
                      mutiSelect: false,
                    },
                  },
                },
              },
            ],
          },
        },
      }),
      //时间表格配置
      dateOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        echoList: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "dateTime",
            label: "时间范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //门店表格配置
      shopOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "shopNo",
            label: "门店编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopTypeName",
            label: "门店类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 200,
            align: "center",
          },
        ],
      },
      goodsTypes: {
        1: "商品",
        2: "品牌",
        3: "类别",
      },
      //商品表格配置
      goodsOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => this.goodsTypes[propValue],
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 200,
            align: "center",
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        curType: "", //当前类型(门店/商品/类别/品牌)
        title: "选择门店",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      ChargeGive: false, //充值赠送整个卡片

      Enable: true, //启用或禁用会员充值规则
      showAudit: true, //审核
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      billType: undefined, //会员充值规则类型
      disabled: false, //禁用状态
      OpenCategory: false, //选择类别开关
      OpenBrand: false, //选择品牌开关
      OpenShopInfo: false, //选择门店信息开关
      OpenVipLeve: false, //选择会员等级开关
      OpenGoods: false, //选择商品信息开关
      loadingBrand: false, //商品品牌加载
      loadingShopInfo: false, //门店资料适用范围表格加载
      loadingGoods: false, //赠送商品表格加载
      loadingVip: false, //会员适用范围表格加载
      loading: false, //加载
      isIconInfo: false, //是否显示基本信息
      isIconRange: false, //是否显示适用范围
      isIconRule: false, //是否显示充值规则
      isIconDiscount: false, //是否显示会员级别折扣
      fillRuleForm: {
        AllowOfflineShop: ["0", "1"], //适用渠道
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        billStatus: "",
        billNo: undefined,
        goodsItems: [], //适用商品
        vipLevelItems: [
          {
            //折扣表格配置
            discountOptions: {
              curListIndex: -1, //当前表格行
              mutiSelect: false, //是否多选
              list: [{}],
              curListIndex: -1, //当前表格下标
              tableCellLabel: "序号",
              tableHeight: undefined, //表格高度
              status: undefined, //单据状态
              hideTotal: true,
              retainColumn: true, //保留表格最后一行
              columns: [
                {
                  prop: "vipPropType",
                  label: "会员属性",
                  type: "localSelect",
                  click: "clickVip",
                  minWidth: 120,
                  align: "center",
                  model: "vipPropType", //
                  filter: "vipPropType",
                  option: {
                    data: [
                      { label: "可用积分", value: 1 },
                      { label: "累计积分", value: 2 },
                      { label: "可用余额", value: 3 },
                      { label: "累计充值", value: 4 },
                    ],
                    label: "label",
                    value: "value",
                    filterable: true,
                  },
                  disabled: false,
                },
                {
                  type: "input",
                  prop: "begValue",
                  label: "开始值(大于等于)",
                  minWidth: 120,
                  align: "center",
                  disabled: false,
                },
                {
                  type: "input",
                  prop: "endValue",
                  label: "结束值(小于)",
                  minWidth: 120,
                  align: "center",
                  disabled: false,
                },
                {
                  type: "input",
                  prop: "discountRate",
                  label: "折扣比率(%)",
                  minWidth: 120,
                  align: "center",
                  disabled: false,
                },
                {
                  prop: "",
                  label: "",
                  minWidth: 200,
                  align: "center",
                },
              ],
            },
          },
        ], //会员折扣
        shopItems: [], //门店适用范围
        shopRangeType: "0", //适用门店
        goodsRangeType: "0", //适用商品
        discountPriority1: "1", //默认优先级会员价
        discountPriority2: "0", //会员折扣
        useTimeRangeType: "0", //适用时间
      },
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      //启用停用
      urlEnable: undefined,
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则添加对应编码格式",
            trigger: ["blur", "change"],
          },
        ],
        billRuleName: [
          {
            required: true,
            message: "请输入方案名称",
            trigger: ["blur", "change"],
          },
        ],
        AllowOfflineShop: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    //侦听是否开启关闭按钮
    "fillRuleForm.billStatus": {
      handler(val) {
        const disabledColumns = ["2", "3"].includes(val);
        this.shopOptions.status = disabledColumns;
        this.goodsOptions.status = disabledColumns;
        this.dateOptions.status = disabledColumns;
        console.log(this.fillRuleForm.vipLevelItems, "会员价格");
        this.fillRuleForm.vipLevelItems = this.fillRuleForm.vipLevelItems.map((item) => ({
          ...item,
          discountOptions: {
            ...item.discountOptions,
            status: disabledColumns,
            columns: item.discountOptions.columns.map((x) => ({
              ...x,
              disabled: disabledColumns,
            })),
          },
        }));
        console.log(this.fillRuleForm.vipLevelItems, "会员价格");
        this.disabled = disabledColumns;
      },
      immediate: true,
    },
    //会员价优先1
    "fillRuleForm.discountPriority1": {
      handler(newVal) {
        if (this.fillRuleForm.discountPriority1 == "1") {
          this.fillRuleForm.discountPriority2 = "0";
        } else {
          this.fillRuleForm.discountPriority2 = "1";
        }
      },
      immediate: true,
    },
    //会员价优先2
    "fillRuleForm.discountPriority2": {
      handler(newVal) {
        if (this.fillRuleForm.discountPriority2 == "1") {
          this.fillRuleForm.discountPriority1 = "0";
        } else {
          this.fillRuleForm.discountPriority1 = "1";
        }
      },
      immediate: true,
    },
  },
  activated() {
    this.getDetail();
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    //获取详情
    async getDetail() {
      this.reset();
      this.billType = 150101;
      this.changeBillType(this.billType);
      if (this.$route.query.billId) {
        this.loading = true;
        try {
          const res = await getOrderDetail(this.$route.query.billId, this.urlDetail);

          //会员充值规则赋值
          this.fillRuleForm = res.data;
          this.shopOptions.list = this.fillRuleForm.shopItems || [];
          this.goodsOptions.list = this.fillRuleForm.goodsItems || [];
          this.fillRuleForm.vipLevelItems = this.fillRuleForm.vipLevelItems.map(
            (item) => {
              // if(item.vipDiscountRuleVipLevelPropItems.length >= 1){
              item.discountOptions = {
                curListIndex: -1, //当前表格行
                mutiSelect: false, //是否多选
                list:
                  item.vipDiscountRuleVipLevelPropItems &&
                  item.vipDiscountRuleVipLevelPropItems.length >= 1
                    ? item.vipDiscountRuleVipLevelPropItems
                    : [{}],
                curListIndex: -1, //当前表格下标
                tableCellLabel: "序号",
                tableHeight: undefined, //表格高度
                status: undefined, //单据状态
                hideTotal: true,
                retainColumn: true, //保留表格最后一行
                columns: [
                  {
                    prop: "vipPropType",
                    label: "会员属性",
                    type: "localSelect",
                    click: "clickVip",
                    minWidth: 120,
                    align: "center",
                    model: "vipPropType", //
                    filter: "vipPropType",
                    option: {
                      data: [
                        { label: "可用积分", value: 1 },
                        { label: "累计积分", value: 2 },
                        { label: "可用余额", value: 3 },
                        { label: "累计充值", value: 4 },
                      ],
                      label: "label",
                      value: "value",
                      filterable: true,
                    },
                    disabled: false,
                  },
                  {
                    type: "input",
                    prop: "begValue",
                    label: "开始值(大于等于)",
                    minWidth: 120,
                    align: "center",
                    disabled: false,
                  },
                  {
                    type: "input",
                    prop: "endValue",
                    label: "结束值(小于)",
                    minWidth: 120,
                    align: "center",
                    disabled: false,
                  },
                  {
                    type: "input",
                    prop: "discountRate",
                    label: "折扣比率(%)",
                    minWidth: 120,
                    align: "center",
                    disabled: false,
                  },
                  {
                    prop: "",
                    label: "",
                    minWidth: 200,
                    align: "center",
                  },
                ],
              };
              // }
              return { ...item };
            }
          );
          this.dateOptions.echoList =
            this.fillRuleForm.vipDiscountRuleSpecialDateItems || [];
          this.dateOptions.list = await getDateChangeDetail(
            this.fillRuleForm.vipDiscountRuleSpecialDateItems || []
          );
          this.$set(this.fillRuleForm, "AllowOfflineShop", []);
          if (this.fillRuleForm.isAllowOfflineShop)
            this.fillRuleForm.AllowOfflineShop.push("0");
          if (this.fillRuleForm.isAllowOnlineShop)
            this.fillRuleForm.AllowOfflineShop.push("1");
          this.$set(this.fillRuleForm, "isAllowOfflineShop", false);
          this.$set(this.fillRuleForm, "isAllowOnlineShop", false);
        } catch (err) {}
        //获取会员充值规则详情
        this.loading = false;
      } else {
        this.disabled = false;
        this.getBillNo();
      }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "openGoodsDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "goodsId", //当前类型(门店/商品/类别/品牌)
            title: "选择商品",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "goods" }),
          };
          break;
        case "openBrandDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "brandId", //当前类型(门店/商品/类别/品牌)
            title: "选择品牌",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "brand" }),
          };
          break;
        case "openCategoryDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "categoryId", //当前类型(门店/商品/类别/品牌)
            title: "选择类别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "category" }),
          };
          break;
        case "openShopDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "shopId", //当前类型(门店/商品/类别/品牌)
            title: "选择门店",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "shop" }),
          };
          break;
        case "openTimeDateDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "timeDate",
            title: "选择时间日期",
            width: 600,
            show: true,
            type: "TimeDateSelect",
            formData: {
              DateTimeList: this.dateOptions.echoList, // 日期
            },
          };
          break;
        case "dialogChange":
          let list = [];
          let dialogType = this.dialogOptions.curType;

          if (dialogType != "timeDate") {
            if (this.dialogOptions.formData.table.check.length <= 0) return;
            list = this.dialogOptions.formData.table.check;
            if (["goodsId", "brandId", "categoryId"].includes(dialogType)) {
              list = this.getTableData(dialogType, list);
              list = [...list, ...this.goodsOptions.list];
              list = uniqWith(list, (x, y) =>
                dialogType == "goodsId"
                  ? x[dialogType] == y[dialogType] && x.unitId == y.unitId
                  : x[dialogType] == y[dialogType]
              );
              this.goodsOptions.list = list;
            } else if (dialogType === "shopId") {
              list = uniqWith(list, (x, y) => x[dialogType] == y[dialogType]);
              this.shopOptions.list = list;
            }
          } else {
            this.dateOptions.echoList = this.dialogOptions.formData.DateTimeList; //方便适用时间回显
            this.dateOptions.list = await getDateChangeDetail(
              this.dialogOptions.formData.DateTimeList || []
            );
          }
          break;
        default:
          break;
      }
    },
    //获取表格数据(商品/类别/品牌)
    getTableData(type, list) {
      let newList = [];
      switch (type) {
        case "goodsId":
          newList = list.flatMap((item) =>
            item.units.map((i) => ({
              ...item,
              ...i,
              lineType: 1,
              lineId: item.goodsId,
              lineName: item.goodsName,
              lineNo: item.goodsNo,
            }))
          );
          console.log(newList, "newList");
          break;
        case "categoryId":
          newList = list.map((item) => ({
            ...item,
            lineType: 3,
            lineId: item.categoryId,
            lineName: item.categoryName,
            lineNo: item.categoryNo,
          }));
          break;
        case "brandId":
          newList = list.map((item) => ({
            ...item,
            lineType: 2,
            lineId: item.brandId,
            lineName: item.brandName,
            lineNo: item.brandNo,
          }));
          break;
        default:
          break;
      }
      return newList;
    },
    //级别折扣
    GetAddLevel() {
      this.fillRuleForm.vipLevelItems.push({
        discountRate: undefined,
        vipLevelId: undefined,
        //折扣表格配置
        discountOptions: {
          curListIndex: -1, //当前表格行
          mutiSelect: false, //是否多选
          list: [{}],
          curListIndex: -1, //当前表格下标
          tableCellLabel: "序号",
          tableHeight: undefined, //表格高度
          status: undefined, //单据状态
          hideTotal: true,
          retainColumn: true, //保留表格最后一行
          columns: [
            {
              prop: "vipPropType",
              label: "会员属性",
              type: "localSelect",
              click: "clickVip",
              minWidth: 120,
              align: "center",
              model: "vipPropType", //
              filter: "vipPropType",
              option: {
                data: [
                  { label: "可用积分", value: 1 },
                  { label: "累计积分", value: 2 },
                  { label: "可用余额", value: 3 },
                  { label: "累计充值", value: 4 },
                ],
                label: "label",
                value: "value",
                filterable: true,
              },
              disabled: false,
            },
            {
              type: "input",
              prop: "begValue",
              label: "开始值(大于等于)",
              minWidth: 120,
              align: "center",
              disabled: false,
            },
            {
              type: "input",
              prop: "endValue",
              label: "结束值(小于)",
              minWidth: 120,
              align: "center",
              disabled: false,
            },
            {
              type: "input",
              prop: "discountRate",
              label: "折扣比率(%)",
              minWidth: 120,
              align: "center",
              disabled: false,
            },
            {
              prop: "",
              label: "",
              minWidth: 200,
              align: "center",
            },
          ],
        },
      });
    },
    //启用停用
    async getEnable(value) {
      if (this.fillRuleForm.billStatus != "2") {
        return this.$message.warning("请操作已审核的方案");
      }
      const obj = {
        billIds: [this.fillRuleForm.billId],
        isStop: value == "启用" ? 0 : 1,
      };
      try {
        await updateEnable(obj, this.urlEnable);
        this.$message.success(`${value}成功`);
      } catch (err) {}
    },
    //接口地址
    changeBillType(billType) {
      if (billType === 150101) {
        //会员折扣规则
        this.urlAdd = "/api/system/vip/base/discount/rule/save";
        this.urlBillStatus = "/api/system/vip/base/discount/rule/updateStatus";
        this.urlDetail = "/api/system/vip/base/discount/rule/getDetail";
        this.urlUpdate = "/api/system/vip/base/discount/rule/update";
        this.urlEnable = "/api/system/vip/base/discount/rule/isStop";
      }
    },

    //审核会员充值规则方法
    async auditBill() {
      if (["2", "3"].includes(this.fillRuleForm.billStatus)) {
        return this.$message.warning("请操作未审核的方案");
      }
      try {
        if (this.fillRuleForm.billStatus != "0") {
          this.$message.warning("请操作未审核的方案");
          return;
        }
        const obj = {
          billIds: [this.fillRuleForm.billId],
          billStatus: 2,
          billType: this.billType,
        };
        //发送审核api
        const res = await updateOrderStatus(obj, this.urlBillStatus);
        this.fillRuleForm.billStatus = res.data.billStatus;
        // 弹出提示
        this.$message.success("审核成功");
      } catch {}
    },

    /** 提交按钮 */
    async submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //判断适用门店 shopRangeType
          if (
            ["1", "2"].includes(this.fillRuleForm.shopRangeType) &&
            this.shopOptions.list.length <= 0
          ) {
            return this.$message.error("请选择门店");
          }
          if (
            ["1", "2"].includes(this.fillRuleForm.goodsRangeType) &&
            this.goodsOptions.list.length <= 0
          ) {
            return this.$message.error("请选择商品");
          }
          this.fillRuleForm.shopItems = this.shopOptions.list;
          this.fillRuleForm.goodsItems = this.goodsOptions.list;
          this.fillRuleForm.vipDiscountRuleSpecialDateItems = this.dateOptions.list;
          //会员级别是否重复
          const newVipLevelItems = uniqWith(
            this.fillRuleForm.vipLevelItems,
            (x, y) => x.vipLevelId == y.vipLevelId
          );
          if (newVipLevelItems.length != this.fillRuleForm.vipLevelItems.length)
            return this.$message.error("会员级别重复,请检查");
          let discountRlus = this.fillRuleForm.vipLevelItems.some((item) =>
            item.discountOptions.list.some(
              (i) =>
                i.vipPropType &&
                (i.begValue == null ||
                  i.begValue == undefined ||
                  i.begValue == "" ||
                  i.endValue == null ||
                  i.endValue == undefined ||
                  i.endValue == "" ||
                  i.discountRate == null ||
                  i.discountRate == undefined ||
                  i.discountRate == "")
            )
          );
          if (discountRlus) return this.$message.error(`请把会员属性填写完整!`);
          discountRlus = this.fillRuleForm.vipLevelItems.some((item) =>
            item.discountOptions.list.some(
              (i) => i.vipPropType && Number(i.begValue) > Number(i.endValue)
            )
          );
          if (discountRlus) return this.$message.error(`会员属性结束值不能小于开始值!`);
          this.fillRuleForm.vipLevelItems = this.fillRuleForm.vipLevelItems.map(
            (item) => ({
              ...item,
              vipDiscountRuleVipLevelPropItems: item.discountOptions.list.filter(
                (i) => i.vipPropType
              ),
            })
          );
          //适用渠道字段转换赋值
          this.fillRuleForm.AllowOfflineShop.forEach((v) => {
            if (v === "0") this.fillRuleForm.isAllowOfflineShop = true; //线下门店
            if (v === "1") this.fillRuleForm.isAllowOnlineShop = true; //线上商城
          });
          this.loading = true;
          if (this.fillRuleForm.billId) {
            try {
              const res = await updateOrder(this.fillRuleForm, this.urlUpdate);
              this.$message.success("保存成功");
              if (isAdd) {
                this.reset();
                this.getBillNo();
              } else {
                this.fillRuleForm.billStatus = res.data.billStatus;
              }
            } catch (err) {}
          } else {
            try {
              const res = await addOrder(this.fillRuleForm, this.urlAdd);
              this.$message.success("保存成功");
              if (isAdd) {
                this.reset();
                this.getBillNo();
              } else {
                this.fillRuleForm.billStatus = res.data.billStatus;
                this.fillRuleForm.billId = res.data.billId;
              }
            } catch (err) {}
          }
          this.loading = false;
        } else {
          this.$message.error("请把带有红星的必填项输入完整!");
        }
      });
    },
    //打开会员等级对话
    getVipLeve() {
      this.OpenVipLeve = true;
    },
    //删除会员等级表格的内容
    deleteRowVips(index, rows) {
      rows.splice(index, 1);
    },

    //删除会员级别折扣
    deleteRowVipLevel(index, rows) {
      if (index === 0) {
        return;
      }
      rows.splice(index, 1);
    },

    // 页面重置
    reset() {
      this.fillRuleForm = {
        AllowOfflineShop: ["0", "1"], //适用渠道
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        billStatus: "",
        billNo: undefined,
        goodsItems: [], //适用商品
        vipLevelItems: [
          {
            //折扣表格配置
            discountOptions: {
              curListIndex: -1, //当前表格行
              mutiSelect: false, //是否多选
              list: [{}],
              curListIndex: -1, //当前表格下标
              tableCellLabel: "序号",
              tableHeight: undefined, //表格高度
              status: undefined, //单据状态
              hideTotal: true,
              retainColumn: true, //保留表格最后一行
              columns: [
                {
                  prop: "vipPropType",
                  label: "会员属性",
                  type: "localSelect",
                  click: "clickVip",
                  minWidth: 120,
                  align: "center",
                  model: "vipPropType", //
                  filter: "vipPropType",
                  option: {
                    data: [
                      { label: "可用积分", value: 1 },
                      { label: "累计积分", value: 2 },
                      { label: "可用余额", value: 3 },
                      { label: "累计充值", value: 4 },
                    ],
                    label: "label",
                    value: "value",
                    filterable: true,
                  },
                  disabled: false,
                },
                {
                  type: "input",
                  prop: "begValue",
                  label: "开始值(大于等于)",
                  minWidth: 120,
                  align: "center",
                  disabled: false,
                },
                {
                  type: "input",
                  prop: "endValue",
                  label: "结束值(小于)",
                  minWidth: 120,
                  align: "center",
                  disabled: false,
                },
                {
                  type: "input",
                  prop: "discountRate",
                  label: "折扣比率(%)",
                  minWidth: 120,
                  align: "center",
                  disabled: false,
                },
                {
                  prop: "",
                  label: "",
                  minWidth: 200,
                  align: "center",
                },
              ],
            },
          },
        ], //会员折扣
        shopItems: [], //门店适用范围
        shopRangeType: "0", //适用门店
        goodsRangeType: "0", //适用商品
        discountPriority1: "1", //默认优先级会员价
        discountPriority2: "0", //会员折扣
        useTimeRangeType: "0", //适用时间
      };
      this.shopOptions.list = [];
      this.goodsOptions.list = [];
      this.dateOptions.list = [];
      this.resetForm("form");
    },
    //获取会员充值规则编号
    async getBillNo() {
      this.loading = true;
      const res = await getBillNo(this.billType);
      this.fillRuleForm.billNo = res;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
}
//基本信息
.baseInfo {
  //折扣优先级
  .discountUpSelect {
    width: 110px !important;
  }
  //大于
  .greater {
    font-size: 20px;
    width: 50px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 22px;
  }
}
//适用范围
.bodyRange {
  padding: 20px 20px 0;
  //适用
  .range {
    margin: 20px 0;
    .el-button {
      margin-bottom: 10px;
    }
  }
  span {
    font-size: 14px;
    margin-right: 40px;
  }
}
//级别折扣
.discountBody {
  padding: 20px;
  .addLevelItem {
    margin-bottom: 20px;
  }
  .addLevel {
    cursor: pointer;
    color: #2386ee;
    margin-left: 80px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
}
::v-deep .sys-remote {
  width: 193px;
}
.wholeBody {
  background-color: rgb(230, 234, 238);
  min-height: 100vh;
  padding: 0px 20px 20px 20px;

  .addBtn {
    width: 100%;
    text-align: right;
    padding: 10px 0;
  }

  //适用范围标题
  .Rangetitle {
    margin-bottom: 20px;
  }
  //适用范围
  .bodyRange {
    //适用会员
    .range {
      margin: 20px 0;
      .el-button {
        margin-bottom: 10px;
      }
    }
    span {
      font-size: 14px;
      margin-right: 40px;
    }
  }

  //标题
  .titleRule {
    font-size: 16px;
    margin-left: 15px;
  }
  //标题图标
  .iconRule {
    color: #ccc;
  }
}

//多选框
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
//输入框
::v-deep .el-input__inner {
  height: 30px;
}
//选择商品
::v-deep .el-button--medium {
  padding: 6px 20px;
}
</style>
